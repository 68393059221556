



































































import {Component, Vue} from "vue-property-decorator";
import Options from "@/models/vue/Options";
import Notification from "@/models/Notification";
import NotificationService from "@/services/NotificationService";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import LangModule from "@/store/LangModule";
import CreateNotificationDialog from "@/components/dialog/CreateNotificationDialog.vue";

@Component( { components: { CreateNotificationDialog } } )
export default class NotificationsView extends Vue {
	lang: any = getModule(LangModule).lang
	notifications: Notification[] = []
	receivers: string = ""
	title: string = ""
	message: string = ""
	dialog: boolean = false
	page: number = 1
	pageCount: number = 0
	loading: boolean = false
	itemsPerPage: number = 10
	totalItems: number = 0
	options: Options = new Options()
	search: string = ""
	headers = [
		{text: this.lang.receiver, value: "receivers", width: "auto", align: "center"},
		{text: this.lang.subject, value: "subject", width: "auto", align: "center"},
		{text: this.lang.date, value: "createdAt", width: "auto", align: "center"},
		{text: this.lang.createdBy, value: "createdBy", width: "auto", align: "center"},
	]

	created() {
		this.refresh()
	}

	refresh() {
		NotificationService.getNotifications(this, this.notifications, this.page - 1, this.itemsPerPage, this.search)
	}

	rowClick(notification: Notification) {
		this.$router.push({path: "/notifications/" + notification.id})
	}

}
