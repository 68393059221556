var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('v-row',{staticClass:"py-1 grey lighten-3",attrs:{"align":"center","no-gutters":""}},[_c('h5',{staticClass:"mx-3 grey--text text--darken-2 font-weight-medium"},[_vm._v(_vm._s(_vm.lang.notifications))]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-3",attrs:{"depressed":"","color":"primary","small":""},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(_vm._s(_vm.lang.newNotification))])],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"auto","append-icon":"mdi-magnify","label":_vm.lang.search},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.refresh($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.notifications,"hide-default-footer":"","loading":_vm.loading,"show-select":false,"loading-text":_vm.lang.loading,"search":_vm.search,"no-results-text":_vm.lang.noResults,"no-data-text":_vm.lang.noResults,"disable-sort":"","height":"450px"},on:{"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"header.receivers",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(_vm._s(header.text))])]}},{key:"header.subject",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(_vm._s(header.text))])]}},{key:"header.createdAt",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(_vm._s(header.text))])]}},{key:"header.createdBy",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(_vm._s(header.text))])]}},{key:"item.receivers",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.receivers.length)+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":"info","label":""}},[_vm._v(" "+_vm._s(item.createdAt.setLocale("es").toFormat('dd MMMM yyyy'))+" ")])]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":"info","label":""}},[_vm._v(" "+_vm._s(item.createdBy.name)+" ")])]}}])})],1)],1)],1),_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":8},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('CreateNotificationDialog',{attrs:{"dialog":_vm.dialog},on:{"update:dialog":function($event){_vm.dialog=$event}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }